<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
      :validation-schema="createValidationSchema"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView } from '@cargill/shared'
import service from '../../api/weeklyDemandAttendanceResumeReportService'

export default {
  components: { CargillCrudMetaView },
  name: 'WeeklyDamandAttendanceResumeReport',
  data() {
    return {
      metadata: null,
      crudService: service
    }
  },
  async created() {
    this.metadata = await service.getMeta()
  }
}
</script>
